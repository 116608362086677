'use client'

import { useTranslations } from 'next-intl'

export default function Error() {
  const t = useTranslations()
  return (
    <div className='relative top-[--fixed-top] min-h-[calc(80vh_-_var(--fixed-top))] w-full'>
      <div className='absolute left-0 right-0 top-[calc(50%_+_var(--fixed-top)_/_2)]  z-[1] -translate-y-full transform text-label-l1 min768:top-[96px] min768:translate-y-0'>
        <h2 className='mb-[4px] text-center text-lg font-semibold'>
          {t('error.title')}
        </h2>
        <p className='text-center text-md'>{t('error.desc')}</p>
      </div>
    </div>
  )
}
